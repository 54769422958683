<template>
    <div class="manage-errors">
        
        <div class="errors">
            <div class="search">
              <el-input v-model="search" placeholder="חיפוש סוג תקלה">
                  <template #prefix>
                      <i class="el-input__icon el-icon-search"></i>
                  </template>
              </el-input>
            </div>
            <div class="add-error">
                <el-button @click="handle_add_new_error" type="success" icon="el-icon-plus" circle></el-button>
            </div>
          <template v-for="error in filter_by_search" :key="error.uid">
              <div class="error">
                  <error @modify="modify_error($event)" @delete="delete_error($event)" :error="error" />
                  </div>
          </template>
           
        </div>
    </div>
  </template>
  
  <script>
  import { ref } from '@vue/reactivity'
  import error from '../../../components/Admin/Shivuk_Rosman/errors/error.vue'
  import { computed, onMounted } from '@vue/runtime-core'
  import {get_errors_from_db,add_new_error_to_db} from '../../../Methods/rosman_shivuk_funcs'
  import Swal from 'sweetalert2'
  
  export default {
      components:{error},
      setup(){
          const search = ref('')
          const errors = ref([])
  
          const handle_add_new_error = ()=>{
              Swal.fire({
                  icon: 'question',
                  title: 'שם תקלה חדשה',
                  input: 'text',
                  confirmButtonText: 'עדכן',
                  cancelButtonText: 'בטל',
                  showCancelButton: true,
                  denyButtonText: 'בטל עדכון',
                  showDenyButton: true
              }).then(async res=>{
                  if(res.isConfirmed && res.value){
                    console.log(res.value);
                    const new_err = await add_new_error_to_db(res.value)
                    errors.value.push(new_err)
                  }
              })
          }
          const filter_by_search = ref(computed(()=>{
              if(search.value=='') return errors.value
              
              return errors.value.filter(error=>{
                  if(error.name.includes(search.value))return error
              })
          }))
          
          const delete_error=(_error)=>{
              const index = errors.value.findIndex(error => error.uid ==_error.uid)
              if(index!=-1){
                  errors.value.splice(index,1)
              }
          }

          const modify_error=(data)=>{
                console.log('data:',data);
              const index = errors.value.findIndex(error => error.uid == data.uid)
              if(index!=-1){
                  errors.value[index].name = data.name
              }
          }

          onMounted(async()=>{
              errors.value = await get_errors_from_db()
              console.log( errors.value);
          })
  
          return{delete_error,modify_error,handle_add_new_error,search,filter_by_search}
      }
  }
  </script>
  
  <style scoped>
      .manage-errors{
          width: 100%;
          height: 100%;
          flex-direction: column;
          display: flex;
          align-items: center;
      }
      .errors{
          width: 375px;
          height: 100%;
          margin-bottom: 5px;
          text-align: center;
          overflow:hidden;
          overflow-y: auto;
      }
      .error{
          margin-bottom: 5px;
          width: 100%;
          height: 50px;
      }
      .add-error{
          width: 100%;
          height: 45px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
      }
      .search{
          width: 100%;
          height: 40px;
      }
  </style>