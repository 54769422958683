<template>
    <div class="error">
        <div class="error-name">
            <h3>{{error.name}}</h3>
        </div>
        <div class="actions">
            <img @click="handle_delete(error)" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Ftrash-bin_375x375.png?alt=media&token=343ab5d5-380b-4c00-9c6d-ca5152b4673b" alt="">
            <img @click="update_error(error)" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fpencil%20(1)_375x375.png?alt=media&token=78a64ec1-c7b4-49d0-ba40-963c4db41bed" alt="">
        </div>    
    </div>
  </template>
  
  <script>
  import {alert_confirm} from '../../../../Methods/Msgs'
  import {projectFirestore} from '../../../../firebase/config'
  import Swal from 'sweetalert2'
  export default {
      props:['error'],
      emits:['delete','modify'],
      setup(_,{emit}){
          const update_error=async(error)=>{
            console.log(error);
              Swal.fire({
                  icon: 'question',
                  title: 'עדכן שם תקלת שירות',
                  input: 'text',
                  confirmButtonText: 'עדכן',
                  cancelButtonText: 'בטל',
                  showCancelButton: true,
                  denyButtonText: 'בטל עדכון',
                  showDenyButton: true
              }).then(async res=>{
                  if(res.isConfirmed && res.value){
                      error.name = res.value
                      console.log(error);
                      await handle_modify_error(error)
                  }
              })
          }
          const handle_delete = async(error)=>{
              alert_confirm(`האם למחוק את ${error.name}`)
              .then(async(res)=>{
                  if(res.isConfirmed){
                      await projectFirestore.collection('Shivuk')
                      .doc('Shivuk').collection('ErrorsServices').doc(error.uid)
                      .delete()
                      emit('delete',error.uid)
                  }
              })
          
          }
          const handle_modify_error = async(error)=>{
                console.log(error);
                await projectFirestore.collection('Shivuk')
                        .doc('Shivuk').collection('ErrorsServices').doc(error.uid)
                        .set({
                            name:error.name
                        },{merge:true})
                        
                        emit('modify',error)
          }
          return{
              handle_delete,
              handle_modify_error,
              update_error
          }
      }
  }
  </script>
  
  <style scoped>
      .error{
          background: #fff;
          width: 100%;
          height: 100%;
          -webkit-box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.15); 
          box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.15);
          border-radius: 10px;
          display: flex;
      }
      .error-name{
          width: 60%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
      }
      .actions{
          width: 40%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
      }
      img{
          cursor: pointer;
          width: 32px;
          height: 32px;
      }
  </style>